
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { fetchMmsIds, fetchTitleFromMmsId } from '@/api/apiService';
import { BCollapse, BButton, BSpinner } from 'bootstrap-vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFolder, faFile } from '@fortawesome/free-solid-svg-icons';

library.add(faFolder, faFile);

interface MmsidObject {
  id: string;
  mmsidTitle?: string;
}

interface FetchMmsIdsResponse {
  mmsids_by_work_id: { [opusId: string]: string[] };
}

interface WorkItem {
  title: string;
  mmsids: string[];
  subWorks: WorkItem[];
  opusId: string;
  groupedMmsIds?: { workId: string; mmsIds: MmsidObject[] }[];
}

@Component({
  components: {
    BCollapse,
    BButton,
    BSpinner,
    FontAwesomeIcon
  }
})
export default class WorkItemComponent extends Vue {
  @Prop({
    default: () => ({}),
    validator: (value: any) => {
      return typeof value.title === 'string' && Array.isArray(value.mmsids);
    }
  }) readonly work!: WorkItem;

  loading: boolean = false;
  error: string = '';
  activeAccordion: string | null = null;

  async created() {
    if (this.work.opusId) {
      await this.initFetchMmsIds();
    }
  }

  async updateTitlesForMmsIds() {
    if (this.work.groupedMmsIds) {
      for (const group of this.work.groupedMmsIds) {
        const promises = group.mmsIds.map(async (mms) => {
          try {
            const title = await fetchTitleFromMmsId(mms.id);
            if (title) {
              const index = group.mmsIds.findIndex(mmsItem => mmsItem.id === mms.id);
              if (index !== -1) {
                this.$set(group.mmsIds, index, { ...group.mmsIds[index], mmsidTitle: title });
              }
            }
          } catch (error) {
            console.error(`Failed to fetch title for MMS ID ${mms.id}:`, error);
          }
        });
        await Promise.all(promises);
      }
    }
  }

  toggleAccordion(workId: string) {
    this.activeAccordion = this.activeAccordion === workId ? null : workId;
  }

  async initFetchMmsIds() {
    this.loading = true;
    try {
      const response = await this.makeApiCall(this.work.opusId);
      if (response) {
        this.processApiResponse(response);
        await this.updateTitlesForMmsIds(); // Ensure titles are updated after fetching mmsids
      } else {
        console.error('API call returned undefined.');
        this.handleError('API call failed to return data.');
      }
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }

  timeout(ms: number) {
    return new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), ms));
  }

  async makeApiCall(opusId: string, attempts: number = 3, timeoutDuration: number = 20000): Promise<FetchMmsIdsResponse | undefined> {
    for (let attempt = 1; attempt <= attempts; attempt++) {
      try {
        const response = await Promise.race([
          fetchMmsIds(opusId),
          this.timeout(timeoutDuration)
        ]);
        return response as unknown as FetchMmsIdsResponse;
      } catch (error) {
        console.error(`Attempt ${attempt} failed: ${error}`);
        if (attempt === attempts) {
          console.error('All attempts failed');
          return undefined;
        }
        await new Promise(resolve => setTimeout(resolve, Math.pow(2, attempt) * 20000));
      }
    }
    return undefined;
  }

  processApiResponse(response: FetchMmsIdsResponse | undefined) {
    if (!response || typeof response.mmsids_by_work_id === 'undefined') {
      console.error('API response is missing the mmsids_by_work_id property.');
      this.handleError('API response structure is incorrect or missing data.');
      return;
    }

    const allMmsIds = new Set<string>();
    const groupedMmsIds = Object.entries(response.mmsids_by_work_id)
      .map(([workId, mmsIds]) => {
        const uniqueMmsIds = mmsIds.filter(mmsId => !allMmsIds.has(mmsId));
        uniqueMmsIds.forEach(mmsId => allMmsIds.add(mmsId));
        return { workId, mmsIds: uniqueMmsIds.map(id => ({ id })) };
      })
      .filter(({ mmsIds }) => mmsIds.length > 0);

    console.log('groupedMmsIds:', groupedMmsIds);

    this.$set(this.work, 'groupedMmsIds', groupedMmsIds);
  }

  handleError(error: any) {
    console.error(`Failed to fetch mmsids: ${error}`);
    if (this.work && 'groupedMmsIds' in this.work) {
      this.work.groupedMmsIds = [];
    }
  }
}

// apiService.ts
import axios from 'axios';

export async function fetchSvdeAgentId(entityId: string) {
  try {
    const response = await axios.get('https://id.bibframe.app/qid2svde', {
      params: {
        qid: entityId,
      },
    });
    return response.data.svde_agent_id;
  } catch (error) {
    console.error(error);
  }
}

export async function fetchWorks(svdeAgentId: string) {
  try {
    const response = await axios.get('https://id.bibframe.app/agent2opus', {
      params: {
        svde_agent: svdeAgentId,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

//opus2mmids
// http://localhost:5000/opus2mmsids

export async function fetchMmsIds(opusId: string): Promise<{ [workId: string]: string[] } | undefined> {
  try {
    const response = await axios.get('https://id.bibframe.app/opus2mmsids', {
      params: {
        opus_id: opusId,
      },
    });
    // Directly return the mmsids_by_work_id object at the top level
    return response.data; // Assuming the entire response body is the mmsids_by_work_id object
  } catch (error) {
    console.error(error);
    return undefined; // Explicitly return undefined in case of an error
  }
}

// fetchTitleFromMmsId
export async function fetchTitleFromMmsId(mmsId: string): Promise<string | undefined> {
  try {
    const response = await axios.get(`https://open-na.hosted.exlibrisgroup.com/alma/01UPENN_INST/bibs/${mmsId}.jsonld`);
    return response.data.title; // Extracting and returning only the title from the response
  } catch (error) {
    console.error(error);
    return undefined; // Explicitly return undefined in case of an error
  }
}

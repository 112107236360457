
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import WorkItemComponent from './WorkItem.vue'; // Import WorkItem component
import { EntityId, EntityKind, QualifiedEntityValue, WBDatatype } from '@/api/types';
import { relatedEntityIds, parseEntityId, idsFromQualifiedEntity, isPropertyId } from '@/api/wikidata';
import { fetchSvdeAgentId, fetchWorks } from '@/api/apiService';

interface Resource {
  title: string;
  uri: string; // Assuming this exists based on the usage in displayWorks method
}

interface WorkItem {
  title: string;
  uri: string;
}

@Component({
  components: {
    'work-item': WorkItemComponent // Use kebab-case for component registration
  }
})
export default class Entity extends Vue {
  @Prop({ required: true }) private entityId!: string;
  @Action private getEntityData!: any;
  @Action private getPropertyDatatypes!: any;
  @Action private requestLabels!: any;
  @Getter private getImages!: any;
  @Getter private getBanner!: any;
  @Getter private getHomepage!: any;
  @Getter private getWikidataUrl!: any;
  @Getter private getWikipediaUrl!: any;
  @Getter private getReasonatorUrl!: any;
  @Getter private getValuesForProperty!: (entityId: EntityId, propertyId: EntityId) => QualifiedEntityValue[] | null;
  @Getter private getPropertyDatatype!: (entityId: EntityId) => WBDatatype | undefined;

  private linkUrls: Array<{ url: string, label: any }> = [];
  private label = this.entityId;
  private aliases: Array<{value: string}> = [];
  private description: string | null = null;
  private images: string[] | null = null;
  private banner: string | null = null;
  private kind: EntityKind | null = null;
  private propertyDatatype: WBDatatype | null = null;
  private superProperties: QualifiedEntityValue[] = [];
  private superClasses: QualifiedEntityValue[] = [];
  private instanceClasses: QualifiedEntityValue[] = [];
  private works: WorkItem[] = [];

  private async updateEntityData() {
    const { kind } = parseEntityId(this.entityId);
    this.kind = kind;
    const data = await this.getEntityData(this.entityId);
    this.label = data.label || this.entityId;
    this.aliases = data.aliases;
    this.description = data.description;
    const related = relatedEntityIds(data.claims);
    this.requestLabels({entityIds: Array.from(related)});
    const properties = Array.from(related).map(entityId => entityId as EntityId).filter(isPropertyId);
    this.getPropertyDatatypes(properties);
    this.images = this.getImages(this.entityId);
    this.banner = this.getBanner(this.entityId);
    if (kind === 'property') {
      this.propertyDatatype = this.getPropertyDatatype(this.entityId) || null;
      this.superProperties = this.getValuesForProperty(this.entityId, 'P1647') || [];
    }
    this.superClasses = this.getValuesForProperty(this.entityId, 'P279') || [];
    this.instanceClasses = this.getValuesForProperty(this.entityId, 'P31') || [];
    const entityIds = ['P1647', 'P279', 'P31'].concat(
      ...this.superProperties.map(idsFromQualifiedEntity),
      ...this.superClasses.map(idsFromQualifiedEntity),
      ...this.instanceClasses.map(idsFromQualifiedEntity));
    this.requestLabels({ entityIds });
  }

  async displayWorks() {
    try {
      const svdeAgentId = await fetchSvdeAgentId(this.entityId);
      const response = await fetchWorks(svdeAgentId);
      if (!response.data?.person?.opuses?.resources) {
        console.error('Invalid response structure:', response);
        return;
      }
      const resources = response.data.person.opuses.resources;
      this.works = resources.map((resource: Resource) => ({
        title: resource.title,
        uri: resource.uri.replace('svde.org', 'penn.svde.org')
      }));
    } catch (error) {
      console.error('Failed to display works:', error);
    }
  }

  async created() {
    this.updateEntityData();
    this.updateLinks();
    this.displayWorks();
  }

  @Watch('entityId')
  private onEntityIdChanged() {
    this.updateEntityData();
    this.displayWorks();
  }

  private updateLinks() {
    const urls: Array<{ url: string, label: any }> = [
      { url: this.getWikidataUrl(this.entityId), label: this.$t('entity.wikidataLink') }
    ];
    const homepage = this.getHomepage(this.entityId);
    if (homepage !== null) {
      urls.push({ url: homepage, label: this.$t('entity.homepageLink') });
    }
    const wikipedia = this.getWikipediaUrl(this.entityId);
    if (wikipedia !== null) {
      urls.push({ url: wikipedia, label: this.$t('entity.wikipediaLink') });
    }
    urls.push({ url: this.getReasonatorUrl(this.entityId), label: this.$t('entity.reasonatorLink') });
    this.linkUrls = urls;
  }

  private get wikidata() {
    return this.getWikidataUrl(this.entityId);
  }

  private get links() {
    return this.linkUrls;
  }
}
